.alert {
    --bs-alert-padding-x: #{px-to-rem(10)};
    --bs-alert-padding-y: #{px-to-rem(10)};

    border: none;
    color: $clr-white;
    font-size: font-size('body-sm','mobile');

    ul {
        margin: 0;
        padding-left: px-to-rem(20);
    }

    li:not(:last-of-type) {
        margin-bottom: px-to-rem(10);
    }

    &-success {
    background-color: clr(success);
    }

    &-danger {
        background-color: clr(danger);
    }
}

.alert-wrapper {
    &:not(:empty) {
        margin-top: px-to-rem(32);
        margin-bottom: px-to-rem(32);
    }
}

.toast-notification {
    padding: px-to-rem(20);
    font-size: px-to-rem(14);
    font-weight: $font-weight-normal;
    line-height: px-to-rem(17);
    color: $white;
    background-color: $clr-dimorange;
    border-radius: px-to-rem(10);

    @include media-breakpoint-down(lg) {
        padding: px-to-rem(10);
    }
}
